.logoIcon {
  height: 16px;
  width: 16px;
}
.textFieldRename {
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
